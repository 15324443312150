
// Note: assume by default that the data grid always contains at least the equivalent of 3 rows for controls, header, footer even if no rows to display
export const enumDataGrid = Object.freeze({
	ROW_HEIGHT: 52,
	ROWS_MIN: 3,
	ROWS_MAX: 10,
})

// If there are too many rows to display in the given array, calculate the capped height of a data grid (which should then be made scrollable)
export const getDataGridCappedHeight = (aArray = [], aRowsMax = enumDataGrid.ROWS_MAX, aRowHeight = enumDataGrid.ROW_HEIGHT, aRowsMin = enumDataGrid.ROWS_MIN) => {
  return aRowHeight * (aRowsMin + Math.min(aRowsMax, aArray?.length ? aArray.length : 0))
}

// Function to generate a 6-digit colour code from any arbitrary index in deterministic manner
// (i.e. same input index should always produce the same colour code)
export const getColorCodeFromIndex = (aIndex, aSaturation = 100, aLightnessSeq = [50, 30, 80], aHueInit = 0, aHueStep = 61) => {
  const hueMax = 360
  const index = Math.round(aIndex)
  const saturation = (Math.round(aSaturation) % 101 + 101) % 101
  const lightnessSeq = aLightnessSeq.map((l) => (Math.round(l) % 101 + 101) % 101)
  const hueInit = ((Math.round(aHueInit) % hueMax + hueMax) % hueMax)
  const hueStep = Math.round(aHueStep)
  const hue = ((hueInit + index * hueStep) % hueMax + hueMax) % hueMax
  const lightness = ((lightnessSeq[Math.floor((index * hueStep) / hueMax) % lightnessSeq.length]) % 101 + 101) % 101
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}
