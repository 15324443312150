import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../CurrentUserContext'

import TableOne from './TableOne'
import TableTwo from './TableTwo'
import TableThree from './TableThree'
import TableFour from './TableFour'
import TableFive from './TableFive'
import LineGraphOne from './LineGraphOne'
import LineGraphTwo from './LineGraphTwo'
import CorrectiveMeasuresTable from '../CorrectiveMeasuresTable'
import './styles.css'

import { enumOrgEntity } from '../../../clientConstants'

const Graph = ({
	koboDeploymentArray,
	departmentId,
	projectCode,
	missionCode,
	cellCode,
	year,
}) => {
	const { t } = useTranslation()
	const months = [
		t('january'),
		t('february'),
		t('march'),
		t('april'),
		t('may'),
		t('june'),
		t('july'),
		t('august'),
		t('september'),
		t('october'),
		t('november'),
		t('december'),
	]
	const currentUser = useCurrentUser()

	// const { formControl } = useStyles()

	//This should return false when user is not a coordo, hq, or superAdmin
	const userIsAssignedToGroup = Boolean(
		currentUser && currentUser.groups.find(({ group }) => group)
	)
	return (
		<div className="charts-container">
			{departmentId === enumOrgEntity.ALL_DEPARTMENTS && (
				<TableOne
					departmentId={departmentId}
					koboDeploymentArray={koboDeploymentArray}
					projectCode={projectCode}
					missionCode={missionCode}
					months={months}
					year={year}
				/>
			)}
			{departmentId !== enumOrgEntity.ALL_DEPARTMENTS && (
				<TableTwo
					departmentId={departmentId}
					koboDeploymentArray={koboDeploymentArray}
					projectCode={projectCode}
					missionCode={missionCode}
					cellCode={cellCode}
					months={months}
					year={year}
				/>
			)}

			{departmentId === enumOrgEntity.ALL_DEPARTMENTS && (
				<TableThree
					departmentId={departmentId}
					koboDeploymentArray={koboDeploymentArray}
					projectCode={projectCode}
					missionCode={missionCode}
				/>
			)}
			{departmentId === enumOrgEntity.ALL_DEPARTMENTS && (
				<LineGraphOne
					departmentId={departmentId}
					koboDeploymentArray={koboDeploymentArray}
				/>
			)}
			{departmentId !== enumOrgEntity.ALL_DEPARTMENTS && (
				<TableFour
					departmentId={departmentId}
					koboDeploymentArray={koboDeploymentArray}
					projectCode={projectCode}
					cellCode={cellCode}
					missionCode={missionCode}
				/>
			)}
			{departmentId !== enumOrgEntity.ALL_DEPARTMENTS && userIsAssignedToGroup && (
				<LineGraphTwo
					departmentId={departmentId}
					koboDeploymentArray={koboDeploymentArray}
					projectCode={projectCode}
					cellCode={cellCode}
					missionCode={missionCode}
				/>
			)}
			{departmentId !== enumOrgEntity.ALL_DEPARTMENTS && (
				<TableFive koboDeploymentArray={koboDeploymentArray} months={months} />
			)}
			<CorrectiveMeasuresTable checks={koboDeploymentArray || []} />
		</div>
	)
}

export default Graph
