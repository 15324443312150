import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useTranslation } from 'react-i18next'
import { TextField, MenuItem, InputLabel, Select } from '@mui/material'

import { useCurrentUser } from '../../CurrentUserContext'

import BreakDownBox from '../BreakDownBox'
import Progress from '../Progress'
import { StyledFormControl } from './useStyles'
import './styles.css'

import { enumOrgEntity, enumCheckStatus, enumDataYears, enumLocalStorageKey } from '../../../clientConstants'
import { sortAlphabetically } from '../../functions'

const MISSION = loader('./graphql/mission.graphql')
const ALL_DEPARTMENTS = loader(
	'../FilterByCellOrMission/graphql/allDepartments.graphql'
)

const selectedDepartmentIdStorageKey = enumLocalStorageKey.SELECTED_DEPARTMENT_ID
const selectedYearStorageKey = enumLocalStorageKey.SELECTED_YEAR

const FilterByProjects = ({ isCoordo, homePage, missionCode, view }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const [selectedStatus, setSelectedStatus] = useState(enumCheckStatus.ALL)
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')
	const [selectedCode, setSelectedCode] = useState(
		currentUser && currentUser?.project?.code
	)
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(() => {
		return window.localStorage.getItem(selectedDepartmentIdStorageKey)
	})
	const [selectedYear, setSelectedYear] = useState(() => {
		return (
			Number(window.localStorage.getItem(selectedYearStorageKey)) ||
			new Date().getFullYear()
		)
	})

	const { data: { mission = null } = {}, loading } = useQuery(MISSION, {
		variables: {
			code: missionCode,
		},
	})

	const {
		data: { allDepartments = null } = {},
		loaing: allDepartmentsLoading,
	} = useQuery(ALL_DEPARTMENTS)

	return loading || allDepartmentsLoading ? (
		<Progress />
	) : (
		currentUser && mission && (
			<div style={{ marginTop: '20px' }}>
				<div className="formsContainer">
					{
						<StyledFormControl variant="outlined" required color="secondary">
							<InputLabel id="projectCode">{t('project')}</InputLabel>
							<Select
								//Disable if user is not coordo
								disabled={!isCoordo}
								labelId="InputLabel"
								name="project"
								value={selectedCode || currentUser.project.code}
								onChange={({ target: { value } }) => {
									setSelectedCode(value)
								}}
							>
								{mission.projects &&
									[
										{ name: enumOrgEntity.ALL_PROJECTS, code: enumOrgEntity.ALL_PROJECTS },
										...sortAlphabetically(mission.projects, "code"),
									].map(({ name, code }) => (
										<MenuItem key={code} value={code}>
											{name === enumOrgEntity.ALL_PROJECTS
												? t('all_projects')
												: `${code} - ${name}`}
										</MenuItem>
									))}
							</Select>
						</StyledFormControl>
					}

					{allDepartments && (
						<StyledFormControl variant="outlined" required color="secondary">
							<InputLabel id="Department">{t('department')}</InputLabel>
							<Select
								labelId="departmentInputLabel"
								name="allDepartments"
								value={selectedDepartmentId || currentUser.departments[0].id}
								onChange={({ target: { value } }) => {
									setSelectedDepartmentId(value)
									window.localStorage.setItem(selectedDepartmentIdStorageKey, value)
								}}
							>
								{[
									{ name: t('all_departments'), id: enumOrgEntity.ALL_DEPARTMENTS },
									...allDepartments,
								].map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</StyledFormControl>
					)}

					<StyledFormControl variant="outlined" required color="secondary">
						<InputLabel id="year">{t('year')}</InputLabel>
						<Select
							labelId="InputLabel"
							name="year"
							value={selectedYear}
							onChange={({ target: { value } }) => {
								window.localStorage.setItem(selectedYearStorageKey, value)
								setSelectedYear(value)
							}}
						>
							{enumDataYears.map(
								(element, i) => (
									<MenuItem key={i} value={element}>
										{element}
									</MenuItem>
								)
							)}
						</Select>
					</StyledFormControl>
				</div>

				{
					//show filter by status and date if view === 'view' or user
					!homePage ? (
						<div>
							<StyledFormControl variant="outlined" required color="secondary">
								<InputLabel id="status">{t('status')}</InputLabel>
								<Select
									labelId="InputLabel"
									name="status"
									value="all"
									onChange={({ target: { value } }) => {
										setSelectedStatus(value)
									}}
								>
									{[
										{ status: t('all'), value: enumCheckStatus.ALL },
										{ status: t('submitted'), value: enumCheckStatus.RECEIVED },
										{ status: t('pending'), value: enumCheckStatus.SENT },
										{ status: t('overdue'), value: enumCheckStatus.OVERDUE },
									].map((el) => (
										<MenuItem key={el.value} value={el.value}>
											{el.status}
										</MenuItem>
									))}
								</Select>
							</StyledFormControl>
							<StyledFormControl variant="outlined" required color="secondary">
								<TextField
									id="outlined-basic"
									label={t('from')}
									variant="outlined"
									name="date"
									type="date"
									color="secondary"
									format="YYYY-MM-DD"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={({ target: { value } }) => {
										setFromDate(new Date(value).setHours(0, 0, 0, 0))
									}}
								/>
							</StyledFormControl>
							<StyledFormControl variant="outlined" required color="secondary">
								<TextField
									id="outlined-basic"
									label={t('to')}
									variant="outlined"
									name="date"
									type="date"
									color="secondary"
									format="YYYY-MM-DD"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={({ target: { value } }) => {
										setToDate(new Date(value).setHours(0, 0, 0, 0))
									}}
								/>
							</StyledFormControl>
						</div>
					) : null
				}
				{selectedCode && (
					<BreakDownBox
						filterBy="mission"
						projectCode={selectedCode}
						selectedCode={missionCode}
						departmentId={selectedDepartmentId || currentUser.departments[0].id}
						selectedStatus={selectedStatus}
						fromDate={fromDate}
						toDate={toDate}
						checkTable={!homePage}
						view={view}
						year={selectedYear}
					/>
				)}
			</div>
		)
	)
}

export default FilterByProjects
