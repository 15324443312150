import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import Progress from '../Progress'

import { enumOrgEntity } from '../../../clientConstants'
import { sortAlphabetically } from '../../functions'
import { getColorCodeFromIndex } from '../../functions/helpers'
import { calculateAvgQuarterScoreByProject } from './functions'

const CELL = loader('./graphql/cell.graphql')

const MISSION = loader('./graphql/mission.graphql')

const LineGraphTwo = ({
	departmentId,
	missionCode,
	cellCode,
	koboDeploymentArray,
	projectCode,
}) => {
	const [projectArr, setProjectArr] = useState()
	const { t } = useTranslation()
	const labels = [
		t('average_Q1'),
		t('average_Q2'),
		t('average_Q3'),
		t('average_Q4'),
	]

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				suggestedMax: 100,
                ticks: {
                    // Include a percent sign in the ticks
                    callback: function(value, index, ticks) {
                        return value + "%";
                    }
                }
			},
		},
	}
	const data = {
		labels,
		datasets: [],
	}

	const [
		missionData,
		{ data: { mission = null } = {}, loading: missionLoading },
	] = useLazyQuery(MISSION)

	const [cellData, { data: { cell = null } = {}, loading: cellLoading }] =
		useLazyQuery(CELL)

	useEffect(() => {
		if (missionCode && missionCode !== enumOrgEntity.ALL_MISSIONS) {
			missionData({
				variables: { code: missionCode },
			})
		} else if (cellCode && cellCode !== enumOrgEntity.ALL_CELLS) {
			cellData({ variables: { code: cellCode } })
		}
	}, [missionCode, cellCode])

	useEffect(() => {
		if (cell) {
			setProjectArr(cell.projects)
		}
		if (mission) {
			setProjectArr(mission.projects)
		}
	}, [cell, mission])

	if (missionLoading || cellLoading) return <Progress />

	return (
		<>
			{projectArr && (
				<>
					<h3>{t('evolution_avg_quarterly_by_project')}</h3>
					<Line
						data={{
							...data,
							datasets: sortAlphabetically(projectArr, "code").map(({ id, code }, i) => {
								const color = getColorCodeFromIndex(i)
								return {
									key: i,
									label: code,
									data: labels.map((quarter, i) =>
										calculateAvgQuarterScoreByProject({
											quarter: i + 1,
											selectedDepartmentId:
												departmentId === enumOrgEntity.ALL_DEPARTMENTS
													? null
													: Number(departmentId),
											projectCode: code,
											koboDeploymentArray,
										})
									),
									fill: false,
									backgroundColor: color,
									borderColor: color,
								}
							}),
						}}
						options={options}
					/>
				</>
			)}
		</>
	)
}

export default LineGraphTwo
