import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import { StyledPaper } from './useStyles'
import Progress from '../Progress'

import { enumOrgEntity } from '../../../clientConstants'
import {
	deploymentsOverAllScoreByMonthAndDepartment,
	// handleCalculatingOverAllScoreForKoboDeploymentArrPerMonth,
} from './functions'

const ALL_DEPARTMENTS = loader(
	'../../pages/Department/graphql/allDepartments.graphql'
)
const PROJECT_COMMENT = loader(
	'./graphql/departmentAndProjectCommentByMonth.graphql'
)

const ALL_COMMENTS_PER_PROJECT = loader(
	'./graphql/allDepartmentsAndProjectCommentsByMonth.graphql'
)
const TableOne = ({
	departmentId,
	koboDeploymentArray,
	projectCode,
	months,
	year,
}) => {
	let totalArr = []
	const { t } = useTranslation()
	const [selectedMonth, setSelectedMonth] = useState()

	const monthsColumns = months.map((month, i) => {
		return {
			field: month,
			headerName: t(month),
			renderCell: ({ row }) => {
				if (row.id === 'total') {
					const findIndex = totalArr.find((obj) => obj.i === i)
					if (findIndex) {
						const avg = Math.round(findIndex.avgTotal / findIndex.devidedBy)
						return `${avg}%`
					}
				} else {
					const avgTotal = deploymentsOverAllScoreByMonthAndDepartment({
						koboDeploymentArray,
						departmentId: Number(row.id),
						month: i,
					})
					if (typeof avgTotal === 'number' && avgTotal > -1) {
						const found = totalArr.find(({ i: index }) => index === i)
						if (found) {
							const newObj = {
								i,
								avgTotal: avgTotal + found.avgTotal,
								devidedBy: found.devidedBy + 1,
							}
							totalArr = totalArr.filter(({ i: index }) => index !== found.i)
							totalArr = [...totalArr, newObj]
						} else {
							totalArr.push({ i, avgTotal, devidedBy: 1 })
						}
					}

					return typeof avgTotal === 'number' && avgTotal > -1
						? `${avgTotal}%`
						: '%'
				}
			},
			flex: 1,
		}
	})

	const gridColumns = [
		{
			field: 'department',
			headerName: t('department'),
			valueGetter: ({ row }) => row?.name,
			flex: 1.5,
		},
		...monthsColumns,
	]

	const { data: { allDepartments = null } = {}, loading: departmentsLoading } =
		useQuery(ALL_DEPARTMENTS)

	const [
		commentByProjectData,
		{
			data: { departmentAndProjectCommentByMonth = null } = {},
			loading: projectCommentLoading,
		},
	] = useLazyQuery(PROJECT_COMMENT)

	const [
		allCommentByProjectData,
		{
			data: { allDepartmentsAndProjectCommentsByMonth = null } = {},
			loading: allCommentsLoading,
		},
	] = useLazyQuery(ALL_COMMENTS_PER_PROJECT)

	// const handleCellClick = (month) => {
	// 	return setSelectedMonth(month)
	// }

	useEffect(() => {
		if (
			selectedMonth &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode &&
			projectCode !== enumOrgEntity.ALL_PROJECTS
		) {
			return commentByProjectData({
				variables: {
					month: selectedMonth,
					departmentId,
					projectCode,
					year,
				},
			})
		}

		if (
			selectedMonth &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode &&
			projectCode !== enumOrgEntity.ALL_PROJECTS
		) {
			return allCommentByProjectData({
				variables: {
					month: selectedMonth,
					projectCode,
					year,
				},
			})
		}
	}, [selectedMonth, departmentId, projectCode])

	if (departmentsLoading) return <Progress />
	return (
		<>
			<h3>{t('results_by_department_month')}</h3>
			<StyledPaper>
				<DataGridPro
					autoHeight
					columns={gridColumns}
					rows={
						//Adding total row
						allDepartments && departmentId === enumOrgEntity.ALL_DEPARTMENTS
							? [...allDepartments, { id: 'total', name: 'total' }]
							: [
									...allDepartments.filter(({ id }) => id === departmentId),
									{ id: 'total', name: 'total' },
							  ]
					}
					components={{ Toolbar: GridToolbar }}
				/>
			</StyledPaper>
		</>
	)
}

export default TableOne
