/**
 * FilterSelect Component
 *
 * The `FilterSelect` component is a reusable React component for rendering a dropdown select input field
 * with options, typically used for filtering data.
 *
 * @param {string} label - The label for the select input field.
 * @param {string} value - The currently selected value for the select input field.
 * @param {array} options - An array of objects representing the options available in the dropdown.
 * @param {function} onChange - A callback function to handle the change event when the selected value changes.
 * @param {string} menuItemValue - (Optional) The key in the option object to be used as the value for the MenuItem.
 *
 * @return {JSX.Element} A dropdown select input field with provided options.
 */
import React from 'react'
import { MenuItem, InputLabel, Select } from '@mui/material'
import { StyledFormControl } from '../FilterByCellOrMission/useStyles'

const FilterSelect = ({ label, value, options, onChange, menuItemValue }) => {
	return (
		<StyledFormControl variant="outlined" required color="secondary">
			<InputLabel id={label.toLowerCase()}>{label}</InputLabel>
			<Select
				labelId={`${label.toLowerCase()}InputLabel`}
				name={label.toLowerCase()}
				value={value}
				onChange={onChange}
			>
				{options.map((option, i) => (
					<MenuItem
						key={i}
						value={menuItemValue ? option[menuItemValue] : option.value}
					>
						{option.label ? option.label :
							option?.code && option?.name
							? `${option.code} - ${option.name}`
							: option?.name && !option?.code
							? option.name
							: option?.code && !option?.name
							? option.code
							: `${option}`}
					</MenuItem>
				))}
			</Select>
		</StyledFormControl>
	)
}

export default FilterSelect
