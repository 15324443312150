/**
 * LatestChecks Component
 *
 * The LatestChecks component displays a list of the latest KoboDeployments based on the selected project and department. It provides filtering options by status and department.
 *
 * @returns {JSX} A component that displays the latest KoboDeployments and allows filtering by status and department.
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
	MenuItem,
	InputLabel,
	Select,
	Checkbox,
	ListItemText,
} from '@mui/material'

import { useCurrentUser } from '../../CurrentUserContext'

import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import DetailedChecksTable from '../../UI/CheckTable/DetailedChecksTable'
import { StyledFormControl } from './useStyles'
import './styles.css'

import { enumCheckStatus, enumLocalStorageKey } from '../../../clientConstants'

const ALL_DEPARTMENTS = loader('../AllChecks/graphql/allDepartments.graphql')
const PROJECT = loader('../Project/graphql/project.graphql')
const KOBO_DEPLOYMENT = loader(
	'./graphql/latestAndUniqueKoboDeploymentByProjectAndDepartment.graphql'
)

const selectedDepartmentIdStorageKey = enumLocalStorageKey.SELECTED_CHECKS_DEPARTMENT_ID

const LatestChecks = () => {
	const { code } = useParams()

	const filterOptions = [
		{ status: enumCheckStatus.RECEIVED, text: 'submitted' },
		{ status: enumCheckStatus.SENT, text: 'pending' },
		{ status: enumCheckStatus.OVERDUE, text: 'overdue' },
		{ status: enumCheckStatus.CANCELED, text: 'canceled' },
	]
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const [filterBy, setFilterBy] = useState([])
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(() => {
		return window.localStorage.getItem(selectedDepartmentIdStorageKey)
	})

	const {
		data: { allDepartments = null } = {},
		loading: allDepartmentsLoading,
	} = useQuery(ALL_DEPARTMENTS)
	const [
		koboDeploymentData,
		{
			data: { latestAndUniqueKoboDeploymentByProjectAndDepartment = null } = {},
			loading,
		},
	] = useLazyQuery(KOBO_DEPLOYMENT)

	const { data: { project = null } = {}, loading: projectLoading } = useQuery(
		PROJECT,
		{
			variables: {
				code: code,
			},
		}
	)

	const handleFiltering = ({ target: { value } }) => {
		return setFilterBy(value)
	}

	useEffect(() => {
		if (currentUser) {
			koboDeploymentData({
				variables: {
					projectCode: code,
					departmentId: selectedDepartmentId || currentUser.departments[0].id,
				},
			})
		}
	}, [currentUser, selectedDepartmentId])

	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			{projectLoading ? (
				<Progress />
			) : (
				<>
					{currentUser && project && <h1>{project.name}</h1>}

					<div className="projectFormContainer">
						{allDepartments && (
							<>
								<StyledFormControl
									variant="outlined"
									required
									color="secondary"
								>
									<InputLabel id="Department">{t('department')}</InputLabel>
									<Select
										labelId="departmentInputLabel"
										name="allDepartments"
										value={
											selectedDepartmentId || currentUser.departments[0].id
										}
										onChange={({ target: { value } }) => {
											setSelectedDepartmentId(value)
											window.localStorage.setItem(
												selectedDepartmentIdStorageKey,
												value
											)
										}}
									>
										{allDepartments.map(({ id, name }) => (
											<MenuItem key={id} value={id}>
												{name}
											</MenuItem>
										))}
									</Select>
								</StyledFormControl>
								<StyledFormControl
									variant="outlined"
									required
									color="secondary"
								>
									<InputLabel id="batchLabel">{t('status')}</InputLabel>
									<Select
										onChange={handleFiltering}
										labelId="InputLabel"
										name="batch"
										multiple
										value={filterBy}
										renderValue={(selected) => {
											const textArr = filterBy.map((el) => {
												const findText = filterOptions.find(
													({ status }) => status === el
												)

												if (findText) {
													return t(findText?.text)
												} else {
													return el
												}
											})
											return textArr.join(', ')
										}}
									>
										{filterOptions.map(({ status, text }, i) => {
											return (
												<MenuItem key={i} value={status}>
													<Checkbox
														checked={
															filterOptions.length
																? Boolean(
																		filterBy.find((option) => option === status)
																  )
																: false
														}
													/>
													<ListItemText primary={t(text)} />
												</MenuItem>
											)
										})}
									</Select>
								</StyledFormControl>
							</>
						)}
					</div>
					{loading ? (
						<Progress />
					) : latestAndUniqueKoboDeploymentByProjectAndDepartment ? (
						<div className="dataGrid-container">
							<DetailedChecksTable
								koboDeploymentArr={
									filterBy.length
										? latestAndUniqueKoboDeploymentByProjectAndDepartment.filter(
												({ status }) => filterBy.includes(status)
										  )
										: latestAndUniqueKoboDeploymentByProjectAndDepartment
								}
								selectedStatus={enumCheckStatus.ALL}
								page={'latestChecks'}
							/>
						</div>
					) : null}
				</>
			)}
		</>
	)
}

export default LatestChecks
