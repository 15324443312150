import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import { useLazyQuery } from '@apollo/client'
// import { loader } from 'graphql.macro'

import { useCurrentUser } from '../../CurrentUserContext'

import CheckTable from '../CheckTable'
import NewsFeed from '../NewsFeed'
import Graph from '../Graph'

import { enumOrgEntity } from '../../../clientConstants'

// const KOBO_DEPLOYMENTS_BY_PROJECT_AND_ALL_DEPARTMENTS = loader(
// 	'../CheckTable/graphql/koboDeploymentsByProjectAndAllDepartments.graphql'
// )

const BreakDownBox = ({
	checkTable,
	departmentId,
	projectCode,
	filterBy,
	selectedCode,
	selectedStatus,
	fromDate,
	toDate,
	year,
}) => {
	const [results, setResults] = useState()
	const [newsFeed, setNewsFeed] = useState(false)
	const currentUser = useCurrentUser()
	const [koboDeploymentArray, setKoboDeploymentArray] = useState([])

	useEffect(() => {
		// setKoboDeploymentArray(null)
		if (
			filterBy === enumOrgEntity.CELL &&
			selectedCode &&
			selectedCode !== enumOrgEntity.ALL_CELLS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode === enumOrgEntity.ALL_PROJECTS
		) {
			axios
				.get('/checks-by-cell', {
					params: {
						cell_code: selectedCode,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.CELL &&
			selectedCode &&
			selectedCode !== enumOrgEntity.ALL_CELLS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode === enumOrgEntity.ALL_PROJECTS
		) {
			//koboDeploymentsByCellAndDepartment
			axios
				.get('/checks-by-cell-department', {
					params: {
						cell_code: selectedCode,
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.MISSION &&
			selectedCode &&
			selectedCode !== enumOrgEntity.ALL_MISSIONS &&
			projectCode === enumOrgEntity.ALL_PROJECTS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//getKoboDeploymentsByMission
			axios
				.get('/checks-by-mission', {
					params: {
						mission_code: selectedCode,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.MISSION &&
			selectedCode &&
			selectedCode !== enumOrgEntity.ALL_MISSIONS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode === enumOrgEntity.ALL_PROJECTS
		) {
			//koboDeploymentsByMissionAndDepartment
			axios
				.get('/checks-by-mission-department', {
					params: {
						mission_code: selectedCode,
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.CELL &&
			selectedCode === enumOrgEntity.ALL_CELLS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			axios
				.get('/checks-by-all-cells', {
					params: {
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.MISSION &&
			selectedCode === enumOrgEntity.ALL_MISSIONS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filtering by all cells and all missions are the same
			axios
				.get('/checks-by-all-cells', {
					params: {
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.CELL &&
			selectedCode === enumOrgEntity.ALL_CELLS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filtering by all cells and a department
			axios
				.get('/checks-by-all-cells-department', {
					params: {
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === enumOrgEntity.MISSION &&
			selectedCode === enumOrgEntity.ALL_MISSIONS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filtering by all mission and a department same endpoint as allCellsAndDepartment
			axios
				.get('/checks-by-all-cells-department', {
					params: {
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			projectCode &&
			projectCode !== enumOrgEntity.ALL_PROJECTS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filtering by project and all departments
			axios
				.get('/checks-by-project-all-departments', {
					params: {
						projectCode,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		}

		if (
			currentUser.departments.length &&
			projectCode &&
			projectCode !== enumOrgEntity.ALL_PROJECTS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS
		) {
			axios
				.get('/checks-by-project-department', {
					params: {
						projectCode,
						departmentId: departmentId || currentUser.department.id,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		}
	}, [filterBy, selectedCode, projectCode, departmentId, year, currentUser])

	useEffect(() => {
		// setKoboDeploymentArray(null)
		if (
			results &&
			projectCode !== enumOrgEntity.ALL_PROJECTS &&
			projectCode &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filter by project and department
			//Show news feed
			setNewsFeed(true)
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === enumOrgEntity.CELL &&
			selectedCode !== enumOrgEntity.ALL_CELLS &&
			selectedCode &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode === enumOrgEntity.ALL_PROJECTS
		) {
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === enumOrgEntity.MISSION &&
			selectedCode !== enumOrgEntity.ALL_MISSIONS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS &&
			projectCode === enumOrgEntity.ALL_PROJECTS
		) {
			//Filter by a mission and department
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === enumOrgEntity.CELL &&
			selectedCode !== enumOrgEntity.ALL_CELLS &&
			projectCode === enumOrgEntity.ALL_PROJECTS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filter by a cell and all departments
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === enumOrgEntity.MISSION &&
			selectedCode !== enumOrgEntity.ALL_MISSIONS &&
			projectCode === enumOrgEntity.ALL_PROJECTS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filter by a mission and all departments
			setKoboDeploymentArray(results)
		}

		if (
			//koboDeploymentByAllCells &&
			results &&
			filterBy === enumOrgEntity.CELL &&
			selectedCode === enumOrgEntity.ALL_CELLS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filter by all cells
			setKoboDeploymentArray(results)
		}

		if (
			results &&
			filterBy === enumOrgEntity.MISSION &&
			selectedCode === enumOrgEntity.ALL_MISSIONS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filter by all missions
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === enumOrgEntity.CELL &&
			selectedCode === enumOrgEntity.ALL_CELLS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS
		) {
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === enumOrgEntity.MISSION &&
			selectedCode === enumOrgEntity.ALL_MISSIONS &&
			departmentId !== enumOrgEntity.ALL_DEPARTMENTS
		) {
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			projectCode &&
			projectCode !== enumOrgEntity.ALL_PROJECTS &&
			departmentId === enumOrgEntity.ALL_DEPARTMENTS
		) {
			//Filtering by project and all departments
			setKoboDeploymentArray(results)
		}
	}, [filterBy, selectedCode, projectCode, departmentId, year, results])

	return (
		<>
			{currentUser &&
				currentUser.project &&
				checkTable === false &&
				newsFeed && (
					<>
						<NewsFeed checks={results || []} />
					</>
				)}
			{koboDeploymentArray.length ? (
				<Graph
					koboDeploymentArray={koboDeploymentArray}
					departmentId={departmentId}
					projectCode={projectCode}
					cellCode={filterBy === enumOrgEntity.CELL ? selectedCode : null}
					missionCode={filterBy === enumOrgEntity.MISSION ? selectedCode : null}
					year={year}
				/>
			) : (
				koboDeploymentArray &&
				checkTable && (
					<CheckTable
						koboDeploymentArray={koboDeploymentArray}
						selectedStatus={selectedStatus}
						fromDate={fromDate}
						toDate={toDate}
					/>
				)
			)}
		</>
	)
}

export default BreakDownBox
