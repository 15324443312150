// Return a copy of the input array sorted in alphabetical order, according to a specific field name, with the option to make the sorting case-insensitive.
const sortAlphabetically = (array, fieldName, caseSensitive = false) => {
	if (array?.length) {
		return array.slice().sort((a, b) => {
			const val_a = (a && fieldName) ? a?.[fieldName] : a
			const val_b = (b && fieldName) ? b?.[fieldName] : b
			if (val_a && val_b) {
				return caseSensitive ? String(val_a).localeCompare(String(val_b)) : 
					String(val_a).toLowerCase().localeCompare(String(val_b).toLowerCase())
			}
			if (val_a) {
				return -1
			} else if (val_b) {
				return 1
			} else {
				return 0
			}
		})
	} else {
		return []
	}
}

export default sortAlphabetically
