import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { TextField, MenuItem, InputLabel, Select } from '@mui/material'

import { useCurrentUser } from '../../CurrentUserContext'

import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import DetailedChecksTable from '../../UI/CheckTable/DetailedChecksTable'
import { StyledButton, StyledFormControl } from './useStyles'
import './styles.css'

import { enumCheckStatus } from '../../../clientConstants'
import { getISODayStrFromDate } from '../../functions'

const KOBO_DEPLOYMENT = loader(
	'./graphql/koboDeploymentByCheckAndProjectFromTo.graphql'
)
const Project = () => {
	const { checkName } = useParams()
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const [selectedStatus, setSelectedStatus] = useState(enumCheckStatus.ALL)
	const [fromDate, setFromDate] = useState(null)
	// (() => {
	// 	return window.localStorage.getItem('project-from-date') || null
	// })
	const [toDate, setToDate] = useState(null)
	// (() => {
	// 	return window.localStorage.getItem('project-to-date') || null
	// })

	const [
		koboDeploymentData,
		{ data: { koboDeploymentByCheckAndProjectFromTo = null } = {}, loading },
	] = useLazyQuery(KOBO_DEPLOYMENT)

	const handleClearFilter = () => {
		window.location.reload()
	}

	//Set the default from - to dates to get checks for the last 12 months (up to and including today -> add 1 day)
	useEffect(() => {
		const today = new Date()
		setFromDate(getISODayStrFromDate((new Date(today)).setMonth(today.getMonth() - 12)))
		setToDate(getISODayStrFromDate((new Date(today)).setDate(today.getDate() + 1)))
	}, [])

	useEffect(() => {
		if (currentUser && fromDate && toDate) {
			koboDeploymentData({
				variables: {
					from: fromDate,
					to: toDate,
					checkName,
					projectCode: currentUser?.project.code,
				},
			})
		}
	}, [currentUser, fromDate, toDate])

	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<>
				<div className="projectFormContainer">
					<StyledFormControl variant="outlined" required color="secondary">
						<InputLabel id="status">{t('status')}</InputLabel>
						<Select
							labelId="InputLabel"
							name="status"
							value={selectedStatus}
							onChange={({ target: { value } }) => {
								setSelectedStatus(value)
							}}
						>
							{[
								{ status: t('all'), value: enumCheckStatus.ALL },
								{ status: t('submitted'), value: enumCheckStatus.RECEIVED },
								{ status: t('pending'), value: enumCheckStatus.SENT },
								{ status: t('overdue'), value: enumCheckStatus.OVERDUE },
							].map((el, i) => (
								<MenuItem key={i} value={el.value}>
									{el.status}
								</MenuItem>
							))}
						</Select>
					</StyledFormControl>
					<StyledFormControl variant="outlined" required color="secondary">
						<TextField
							id="outlined-basic"
							label={t('from')}
							variant="outlined"
							name="date"
							type="date"
							color="secondary"
							format="YYYY-MM-DD"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={({ target: { value } }) => {
								// setFromDate(new Date(value).setHours(0, 0, 0, 0))
								setFromDate(value)
								// window.localStorage.setItem('project-from-date', value)
							}}
						/>
					</StyledFormControl>
					<StyledFormControl variant="outlined" required color="secondary">
						<TextField
							id="outlined-basic"
							label={t('to')}
							variant="outlined"
							name="date"
							type="date"
							color="secondary"
							format="YYYY-MM-DD"
							// value={toDate}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={({ target: { value } }) => {
								// setToDate(new Date(value).setHours(0, 0, 0, 0))
								setToDate(value)
								// window.localStorage.setItem('project-to-date', value)
							}}
						/>
					</StyledFormControl>

					<StyledButton
						type="submit"
						variant="contained"
						color="secondary"
						onClick={handleClearFilter}
					>
						{t('clear_filter')}
					</StyledButton>
				</div>
				{loading ? (
					<Progress />
				) : koboDeploymentByCheckAndProjectFromTo ? (
					<div style={{ margin: 'auto', maxWidth: '90%' }}>
						<DetailedChecksTable
							koboDeploymentArr={koboDeploymentByCheckAndProjectFromTo}
							selectedStatus={selectedStatus}
						/>
					</div>
				) : null}
			</>
		</>
	)
}

export default Project
