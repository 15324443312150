import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import Progress from '../Progress'
import { StyledPaper } from './useStyles'

import { enumOrgEntity } from '../../../clientConstants'
import { sortAlphabetically } from '../../functions'
import { enumDataGrid, getDataGridCappedHeight } from '../../functions/helpers'
import { renderQuarterColumns } from './functions'

const CELL = loader('./graphql/cell.graphql')

const MISSION = loader('./graphql/mission.graphql')

const ALL_PROJECTS = loader('./graphql/projects.graphql')

const TableFour = ({
	departmentId,
	missionCode,
	cellCode,
	koboDeploymentArray,
	projectCode,
}) => {
	let totalArr = []
	const { t } = useTranslation()
	const [projectArr, setProjectArr] = useState()
	const [gridColumns, setGridColumns] = useState([])
	const quarters = [
		t('average_Q1'),
		t('average_Q2'),
		t('average_Q3'),
		t('average_Q4'),
	]

	const [resultsDataGridRows, setResultsDataGridRows] = useState([])

	useEffect(() => {
		if (projectCode && koboDeploymentArray) {
			const quarterColumns = renderQuarterColumns({
				quarters,
				totalArr,
				departmentId,
				koboDeploymentArray,
				project: true,
			})
			setGridColumns([
				{
					field: 'project',
					headerName: t('project'),
					valueGetter: ({ row }) => row?.code,
					flex: 1.5,
				},
				...quarterColumns,
			])
		}
	}, [departmentId, koboDeploymentArray, projectCode])

	const [
		missionData,
		{ data: { mission = null } = {}, loading: missionLoading },
	] = useLazyQuery(MISSION)

	const [cellData, { data: { cell = null } = {}, loading: cellLoading }] =
		useLazyQuery(CELL)

	const [
		getAllProjects,
		{ data: { projects = null } = {}, loading: projectsLoading },
	] = useLazyQuery(ALL_PROJECTS)

	useEffect(() => {
		if (missionCode && missionCode !== enumOrgEntity.ALL_MISSIONS) {
			missionData({
				variables: { code: missionCode },
			})
		} else if (cellCode && cellCode !== enumOrgEntity.ALL_CELLS) {
			cellData({ variables: { code: cellCode } })
		}
	}, [missionCode, cellCode])

	useEffect(() => {
		if (cellCode === enumOrgEntity.ALL_CELLS || missionCode === enumOrgEntity.ALL_MISSIONS) {
			getAllProjects()
		}
	}, [cellCode, missionCode])

	useEffect(() => {
		if (cell) {
			setProjectArr(cell.projects)
		}
		if (mission) {
			setProjectArr(mission.projects)
		}
		if (projects) {
			setProjectArr(projects)
		}
	}, [cell, mission, projects])

	useEffect(() => {
		if (projectArr?.length) {
			const lastRow = { id: 'total', code: 'total' }
			setResultsDataGridRows(
				!projectCode || projectCode === enumOrgEntity.ALL_PROJECTS
					? [...sortAlphabetically(projectArr, "code"), lastRow]
					: [...sortAlphabetically(projectArr.filter(({ code }) => code === projectCode), "code"), lastRow]
			)
		}
	}, [projectArr, projectCode])

	if (missionLoading || cellLoading) return <Progress />

	return (
		<>
			{resultsDataGridRows && (
				<>
					<h3>{t('avg_quarter_by_project')}</h3>
					{/* If too many results rows, cap the height of the data grid and make it scrollable */}
					<StyledPaper
						style={{
							height: `${getDataGridCappedHeight(resultsDataGridRows)}px`,
						}}
					>
						<DataGridPro
							rowHeight={enumDataGrid.ROW_HEIGHT}
							columns={gridColumns}
							rows={resultsDataGridRows}
							components={{ Toolbar: GridToolbar }}
						/>
					</StyledPaper>
				</>
			)}
		</>
	)
}

export default TableFour
