export const TRANSLATIONS_FR = {
	select_file: 'Sélectionnez un fichier',
	welcome_msg: "Bienvenue dans l'Outil de Contrôle Interne v3.0",
	welcome_home: "Bienvenue à la page principale de WeCheck",
	headquarters: 'Siège',
	cell: 'Cellule',
	mission: 'Mission',
	project: 'Projet',
	code: 'Code',
	name: 'Nom',
	department: 'Département',
	departments: 'Départements',
	joined_groups: 'Groupes rejoints',
	file_upload: 'Importer Fichier',
	upload_file_to: 'Importer Fichier pour',
	create_check: 'Créer un contrôle',
	select_xlsx_file_and_drag: 'Sélectionnez le fichier .xlsx ou faites-le glisser ici',
	check_date_period: "Date d'envoi et Période",
	period: 'période',
	month: 'mois',
	set_send_date_and_period: "Choisir Date d'envoi et Période",
	create: 'Créer',
	save: 'Enregistrer',
	modify: 'Modifier',
	update: 'Mettre à jour',
	delete: 'Effacer',
	set_period: "Choisir période d'envoi",
	set_date: "Choisir date d'envoi",
	future_date_error: "Choisir une date future s'il vous plaît",
	filter_by: 'Filtrer par',
	checks: 'Contrôles',
	submitted_checks: 'Contrôles envoyés',
	pending_checks: 'Contrôles en attente',
	overdue_checks: 'Contrôles non envoyés',
	of_targets_hit: 'objectifs à atteindre',
	the_overall_performance: 'Performance globale',
	keep_track: 'Veuillez faire le suivi des resultats globaux',
	you_have_submitted: 'vous avez envoyé',
	hello: 'Bonjour',
	view_answers: 'Visualiser réponses',
	you_have_answered_questions: 'Vous avez répondu à {{count}} questions',
	receive_date: 'Date de réception',
	send_date: "Date d'envoi",
	status: 'Statut',
	view: 'Visualiser',
	submit_date: "Date d'envoi",
	project_code: 'Code projet',
	check: 'Contrôle',
	check_form: 'Formulaire de contrôles',
	archived: 'Archivé',
	all: 'Tous',
	sent: 'Envoyé',
	received: 'Reçu',
	overdue: 'Hors délai',
	link: 'Lien',
	click_here: 'Cliquez ici',
	to_review_your_answers: 'visualiser vos réponses',
	recently_have_submitted: 'Vous avez récemment envoyé',
	one: 'un',
	pending: 'En attente',
	completed: 'Terminé',
	to_complete_submitting_checks:
		"Pour terminer l'envoi de vos contrôles mensuels",
	you_have: 'Vous avez',
	is: 'est',
	are: 'sont',
	corrective_measures: 'Mesures correctives',
	the_score_is: 'Le resultat est',
	priority: 'Priorité',
	corrective_measures_thanks: "Merci d'avoir envoyé vos mesures correctives",
	corrective_measures_form: 'Formulaire de mesures correctives',
	form_error_msg: "S'assurer que tous les champs sont remplis",
	submitted_corrective_measures: 'Envoyé des mesures correctives',
	score: 'Score',
	submit_corrective_measures: 'Envoyer des mesures correctives',
	corrective_measures_required: 'Mesures correctives requises',
	the_function_in_charge: 'La fonction responsable',
	due_date: "Date d'échéance",
	low: 'bas',
	received_checks: 'contrôles reçus',
	medium: 'moyen',
	high: 'elevé',
	all_missions: 'Toutes les missions',
	all_cells: 'Toutes les cellules',
	all_projects: 'Tous les projets',
	all_departments: 'Tous les départements',
	from: 'de',
	to: 'à',
	corrective_measures_score: 'résultats mesures correctives',
	graph: 'graphe',
	year: 'année',
	num_of_received_checks: '# de contrôles reçus',
	num_of_submitted_checks: '# de contrôles envoyés',
	num_of_pending_checks: '# de contrôles en attente',
	num_of_overdue_checks: '# de contrôles hors délai',
	num_of_sent_checks: '# de contrôles envoyés',
	january: 'Janvier',
	february: 'Février',
	march: 'Mars',
	april: 'Avril',
	may: 'Mai',
	june: 'Juin',
	july: 'Juillet',
	august: 'Août',
	september: 'Septembre',
	october: 'Octobre',
	november: 'Novembre',
	december: 'Décembre',
	sent_checks: 'contrôles envoyés',
	submit: 'Envoyer',
	home: 'Accueil',
	manage_projects: 'Gérer des projets',
	upload: 'télécharger',
	sign_out: 'Se déconnecter',
	sign_in: 'Se connecter',
	group: 'Groupe',
	groups: 'Groupes',
	group_name: 'Nom de groupe',
	create_group: 'Créer un groupe',
	assign_projects_to_groups: 'Attribuer des projets à des groupes',
	add_groups_to_checks: 'Ajouter des groupes aux contrôles',
	back_home: 'Retour à la page principale',
	email: 'e-mail',
	out_of_office: 'Absence du bureau',
	manage_out_of_office: "Gérer l'absence du bureau",
	manage_users: 'Gérer les utilisateurs',
	canceled: 'Annulé',
	invalid: 'Invalide',
	impact: 'Impact',
	person_in_charge: 'Personne en charge',
	in_charge: 'en charge',
	corrective_measures_link: 'Lien mesures correctives',
	sample: 'Échantillon',
	monthly_pending_checks: 'Contrôles mensuels en attente',
	project_summary: 'Résumé du projet',
	mission_summary: 'Résumé de la mission',
	summary_comment_text: 'Veuillez ajouter un commentaire ici pour résumer',
	summary_comment: 'commentaire de synthèse',
	about_1:
		"L'Outil de Contrôles Internes (IMT/WeCheck) est un exercice d'auto-évaluation basé sur un ensemble de contrôles prédéfinis à effectuer régulièrement sur tous les projets et dans toutes les missions par tous les responsables de département (Approvisionnement, Logistique, Finance, Ressources Humaines, Médical et Opérations).",
	about_2:
		"À travers cet exercice, les superviseurs des départements contrôlent si les procédures ou processus de base validés par MSF-OCB sont correctement et systématiquement appliqués dans leurs départements. C'est donc un outil important pour la gestion des projets par les managers de département et le coordinateur de projet.",
	label: 'Libellé',
	category: 'Catégorie',
	last_send_date: 'Date du dernier envoi',
	created_at: 'Date de création',
	timing: 'Horaire',
	project_comment: 'commentaire du projet',
	mission_comment: 'commentaire de mission',
	period_checks: 'contrôles périodiques',
	monthly_completed_checks: 'Contrôles effectués mensuellement',
	support: 'Support',
	average_Q1: 'Moyenne T1',
	average_Q2: 'Moyenne T2',
	average_Q3: 'Moyenne T3',
	average_Q4: 'Moyenne T4',
	clear_filter: 'Effacer le filtre',
	department_not_present: 'département non présent',
	results_by_department_month: 'Résultats par département par mois',
	results_by_project_month: 'Résultats par projet par mois',
	avg_quarter_by_department: 'Résultats trimestriels moyens par département',
	avg_quarter_by_project: 'Résultats trimestriels moyens par projet',
	evolution_avg_quarterly_by_department:
		'Évolution des résultats trimestriels moyens par département',
	evolution_avg_quarterly_by_project:
		'Évolution des résultats moyens par trimestre et par projet',
	list_of_cm: 'Liste des mesures correctives',
	checks_are_out: 'Les contrôles pour {{month}} {{year}} sont maintenant disponibles !!',
	here: 'ici',
	checks_home_page_msg:
		"N'oubliez pas de compléter votre suivi mensuel avant la fin du mois en cliquant",
	checks_are_still_pending: 'les contrôles sont toujours en attente',
	great_job: 'Beau travail',
	checks_have_been_submitted: 'les contrôles ont été envoyés',
	present: 'présent',
	not_present: 'pas présent',
	departments_checks_results_by_month:
		'Résultats des contrôles du département par mois',
	submitted: 'Envoyé',
	comment: 'Commentaire',
	checks_of_month: 'les contrôles de mois de',
	have_been_completed: 'on bien été complétés',
	error: 'Erreur',
	access_error: "Vous essayez d'accéder à WeCheck en utilisant cet email",
	screenshot_msg: "Veuillez prendre une capture d'écran et l'envoyer à",
	change_request:
		"Si vous souhaitez demander une modification / suppression / ajout d'un contrôle, veuillez contacter",
	following_info: 'avec les informations suivantes',
	your_mission: 'votre mission',
	your_project: 'votre projet',
	your_department: 'votre département',
	change_question: 'question que vous souhaitez modifier',
	contextual_reason: 'motif contextuel',
	techincal_contact:
		'Si vous rencontrez des problèmes techniques, veuillez contacter',
	view_questions: 'Afficher les questions',
	success_request_msg: 'Votre demande a été envoyée avec succès !',
	non_inOfficeDate_msg: `Remarque : Si vous ne souhaitez pas définir de date de retour au bureau, veuillez cliquer sur "Clear" (Effacer) en définissant la date de fin comme le montre la capture d'écran ci-dessous, puis cliquez sur "Enregistrer".`,
	mark_ooo_msg: `Ce projet montrera que l'ensemble de votre département est actuellement indisponible, donc aucun contrôle ne sera envoyé à votre département jusqu'à ce qu'il soit à nouveau disponible.`,
	send: 'Envoyer',
	no_user_found:
		"Il n'y a aucun utilisateur affecté à ce projet ou à ce département",
	send_now: 'Envoyer Maintenant',
	user: 'Utilisateur',
	send_check_error:
		"Une erreur s'est produite lors de l'envoi du contrôle, veuillez réessayer plus tard ou contacter l'administrateur",
	send_check_success_msg: 'Le contrôle a été envoyé avec succès',
	wait: "Attendez s'il vous plaît !",
	powerbi_report: 'Rapport PowerBI',
	no_user_assigned_department_project: "Aucun utilisateur n'est affecté à ce département dans ce projet",
	n_a: 'N/A',
}
