import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Container,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
} from '@mui/material'

import CheckboxArray from '../CheckboxArray'
import './styles.css'

const ProjectsList = ({ projects }) => {
	const { t } = useTranslation()
	return (
		<>
			<div>
				{projects && (
					<Container className="topContainer">
					<Table
						stickyHeader
						aria-label="sticky table"
						className="topContainer"
					>
						<TableHead>
							<TableRow>
								<TableCell><h2 className="projectNameContainer">{t('project')}</h2></TableCell>
								<TableCell><h2>{t('groups')}</h2></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
					{projects.map(({ id, code, name, batches }) => {
						return (
							<TableRow key={id} className="batchProjectContainer">
							<TableCell>
								<div className="projectNameContainer">
									<span>
										{name} - {code}
									</span>
								</div>
							</TableCell>
							<TableCell>
								<CheckboxArray selectedBatches={batches} projectCode={code} />
							</TableCell>
							</TableRow>
						)
					})}
						</TableBody>
					</Table>
					</Container>
				)}
			</div>
		</>
	)
}

export default ProjectsList
