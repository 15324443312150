//This function to handle the calculating of the average score by check per month
//Note: use UTC time like the server, not local time from the client, to avoid time zone issues (e.g. 01-Jan AM on server may still be 31-Dec PM on client!)

import { enumCheckStatus } from '../../../../clientConstants'

const calculateAvgScoreByCheckPerMonth = ({
	checkName,
	koboDeploymentArray,
	month,
}) => {
	const deploymentsForThisMonth = koboDeploymentArray.filter(
		({ status, createdAt, check, score }) =>
			status !== enumCheckStatus.CANCELED &&
			new Date(createdAt).getUTCMonth() === month &&
			check.name === checkName &&
			typeof score === 'number' &&
			score > -1
	)

	const scoreArray = deploymentsForThisMonth.map(({ score }) => score)
	if (scoreArray.length) {
		const avg = parseInt(
			scoreArray.reduce((a, b) => a + b) / deploymentsForThisMonth.length
		)
		return typeof avg === 'number' ? avg : null
	}
}

export default calculateAvgScoreByCheckPerMonth
