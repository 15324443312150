/**
 * FilterByCellOrMission Component
 *
 * The `FilterByCellOrMission` component is a React component responsible for filtering data based on cell or mission,
 * and it is used in various parts of the application.
 *
 * @param {boolean} checkTable - A flag to indicate whether to display the filter by status and date for the table.
 * @param {boolean} hQHomePage - A flag to indicate whether the component is used on the HQ home page.
 * @param {string} view - The view to display.
 * @param {boolean} outOfOffice - A flag to indicate if the component is used for out-of-office management.
 *
 * @return {JSX.Element} A component that allows users to filter data by cell or mission and other criteria.
 */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import { useCurrentUser } from '../../CurrentUserContext'

import OooTable from '../OooTable'
import Progress from '../Progress'
import BreakDownBox from '../BreakDownBox'
import FilterSelect from '../FilterSelect'
import DateFilter from '../DateFilter'
import './styles.css'

import { enumOrgEntity, enumCheckStatus, enumDataYears, enumLocalStorageKey } from '../../../clientConstants'
import { sortAlphabetically } from '../../functions'

const ALLDEPARTMENTS = loader('./graphql/allDepartments.graphql')
const CELLS = loader('./graphql/cells.graphql')
const MISSIONS = loader('./graphql/missions.graphql')

const filterByStorageKey = enumLocalStorageKey.SELECTED_ORG_ENTITY_TYPE
const selectedCodeStorageKey = enumLocalStorageKey.SELECTED_CELL_OR_MISSION_CODE
const selectedProjectCodeStorageKey = enumLocalStorageKey.SELECTED_PROJECT_CODE
const selectedDepartmentIdStorageKey = enumLocalStorageKey.SELECTED_DEPARTMENT_ID
const selectedYearStorageKey = enumLocalStorageKey.SELECTED_YEAR

const FilterByCellOrMission = ({
	checkTable,
	hQHomePage,
	view,
	outOfOffice,
}) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const [forceReloadKey, setForceReloadKey] = useState(0)
    const forceReload = () => {
        setForceReloadKey(prevKey => prevKey + 1)
    }

	const [selectedStatus, setSelectedStatus] = useState(enumCheckStatus.ALL)
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')
	const [filterBy, setFilterBy] = useState(() => {
		return window.localStorage.getItem(filterByStorageKey) || enumOrgEntity.MISSION
	})
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(() => {
		return window.localStorage.getItem(selectedDepartmentIdStorageKey) || null
	})
	const [selectedCode, setSelectedCode] = useState(() => {
		return window.localStorage.getItem(selectedCodeStorageKey) || null
	})
	const [selectedProjectCode, setSelectedProjectCode] = useState(() => {
		return window.localStorage.getItem(selectedProjectCodeStorageKey) || null
	})
	const [selectedYear, setSelectedYear] = useState(
		() =>
			Number(window.localStorage.getItem(selectedYearStorageKey)) ||
			new Date().getFullYear()
	)

	const {
		data: { allDepartments = null } = {},
		loading: allDepartmentsLoading,
	} = useQuery(ALLDEPARTMENTS)
	const { data: { cells = null } = {}, cellsLoading } = useQuery(CELLS)
	const { data: { missions = null } = {}, missionsLoading } = useQuery(MISSIONS)

	return (
		<div key={forceReloadKey}>
			{allDepartmentsLoading || cellsLoading || missionsLoading ? (
				<Progress />
			) : (
				<>
					{cells && missions && (
						<>
							{/* Render filter options */}
							<div className="formContainer">
								{/* Filter by Cell or Mission */}
								<FilterSelect
									label={t('filter_by')}
									value={filterBy}
									options={[
										{label: t('cell'), value: enumOrgEntity.CELL},
										{label: t('mission'), value: enumOrgEntity.MISSION},
									]}
									onChange={({ target: { value } }) => {
										setFilterBy(value)
										window.localStorage.setItem(filterByStorageKey, value)
										setSelectedCode(null)
										window.localStorage.removeItem(selectedCodeStorageKey)
										setSelectedProjectCode(null)
										window.localStorage.removeItem(selectedProjectCodeStorageKey)
										forceReload()
									}}
								/>
								{/* Filter by Cell or Mission Code */}
								<FilterSelect
									menuItemValue="code"
									label={filterBy === enumOrgEntity.MISSION ? t('mission') : t('cell')}
									value={(outOfOffice ? (selectedCode === enumOrgEntity.ALL_CELLS || selectedCode === enumOrgEntity.ALL_MISSIONS ? '' : selectedCode) :
										selectedCode) || ''}
									options={
										filterBy === enumOrgEntity.CELL
											? 
												(outOfOffice ? [] : [{ label: t('all_cells'), code: enumOrgEntity.ALL_CELLS },])
												.concat(sortAlphabetically(cells, "code"))
											: 
												(outOfOffice ? [] : [{ label: t('all_missions'), code: enumOrgEntity.ALL_MISSIONS },])
												.concat(sortAlphabetically(missions, "code"))
									}
									onChange={({ target: { value } }) => {
										setSelectedCode(value)
										window.localStorage.setItem(selectedCodeStorageKey, value)
										const projectCode = outOfOffice ? '' : enumOrgEntity.ALL_PROJECTS
										setSelectedProjectCode(projectCode)
										window.localStorage.setItem(selectedProjectCodeStorageKey, projectCode)
										if (selectedDepartmentId === null && !outOfOffice) {
											setSelectedDepartmentId(enumOrgEntity.ALL_DEPARTMENTS)
											window.localStorage.setItem(selectedDepartmentIdStorageKey, enumOrgEntity.ALL_DEPARTMENTS)
										}
									}}
								/>
								{/* Filter by Project Code */}
								<FilterSelect
									menuItemValue="code"
									label={t('project')}
									value={(outOfOffice ? (selectedProjectCode === enumOrgEntity.ALL_PROJECTS ? '' : selectedProjectCode) :
										selectedProjectCode) || ''}
									options={
										(outOfOffice ? [] : [{ label: t('all_projects'), code: enumOrgEntity.ALL_PROJECTS },])
										.concat(
											selectedCode &&
											selectedCode !== enumOrgEntity.ALL_CELLS &&
											filterBy === enumOrgEntity.CELL
											? sortAlphabetically(cells.find(({ code }) => code === selectedCode)?.projects, "code")
											: selectedCode &&
											  filterBy === enumOrgEntity.MISSION &&
											  selectedCode !== enumOrgEntity.ALL_MISSIONS
											? sortAlphabetically(missions.find(({ code }) => code === selectedCode)?.projects, "code")
											: []
										)
									}
									onChange={({ target: { value } }) => {
										const newValue = outOfOffice && value === enumOrgEntity.ALL_PROJECTS ? '' : value
										setSelectedProjectCode(newValue)
										window.localStorage.setItem(selectedProjectCodeStorageKey, newValue)
									}}
								/>
								{/* Filter by Department */}
								<FilterSelect
									menuItemValue="id"
									label={t('department')}
									value={
										outOfOffice ? ((selectedDepartmentId && selectedDepartmentId !== enumOrgEntity.ALL_DEPARTMENTS ? selectedDepartmentId : 
										allDepartments.some(e => e.id === currentUser.departments[0].id) ? currentUser.departments[0].id : '') || '')
										: ((selectedDepartmentId || (allDepartments.some(e => e.id === currentUser.departments[0].id) ? currentUser.departments[0].id : '')) || '')
									}
									options={
										(outOfOffice ? [] : [{ name: t('all_departments'), id: enumOrgEntity.ALL_DEPARTMENTS },])
										.concat(allDepartments)
										.map(({ id, name }) => ({ label: name, id }))}
									onChange={({ target: { value } }) => {
										setSelectedDepartmentId(value)
										window.localStorage.setItem(selectedDepartmentIdStorageKey, value)
									}}
								/>
							</div>

							{
								//Display additional filters based on the view type
								//show the filter by status and date when the view is 'view' or 'checkTable' === true
								checkTable ? (
									<div>
										<FilterSelect
											label={t('status')}
											value={selectedStatus}
											options={[
												{ label: t('all'), value: enumCheckStatus.ALL },
												{ label: t('received'), value: enumCheckStatus.RECEIVED },
												{ label: t('sent'), value: enumCheckStatus.SENT },
												{ label: t('overdue'), value: enumCheckStatus.OVERDUE },
											]}
											onChange={({ target: { value } }) => {
												setSelectedStatus(value || '')
											}}
										/>
										<DateFilter
											label={t('from')}
											value={fromDate}
											onChange={({ target: { value } }) =>
												setFromDate(new Date(value).setHours(0, 0, 0, 0))
											}
										/>
										<DateFilter
											label={t('to')}
											value={toDate}
											onChange={({ target: { value } }) =>
												setToDate(new Date(value).setHours(0, 0, 0, 0))
											}
										/>
									</div>
								) : (
									<>
										{/* Year Filter */}
										{!outOfOffice && (
											<FilterSelect
												label={t('year')}
												value={selectedYear}
												options={enumDataYears.map((element, i) => ({
													label: element,
													value: element,
												}))}
												onChange={({ target: { value } }) => {
													window.localStorage.setItem(selectedYearStorageKey, value)
													setSelectedYear(value)
												}}
											/>
										)}
									</>
								)
							}
							{/* Conditional rendering of OooTable component for out-of-office management */}
							{outOfOffice &&
								selectedDepartmentId &&
								selectedProjectCode &&
								selectedDepartmentId !== enumOrgEntity.ALL_DEPARTMENTS &&
								selectedProjectCode !== enumOrgEntity.ALL_PROJECTS && (
									<OooTable
										showEmail
										departmentId={selectedDepartmentId}
										departmentName={allDepartments.find(({ id }) => id === selectedDepartmentId)?.name}
										projectCode={selectedProjectCode}
									/>
								)}
							{/* Render BreakDownBox component based on different views */}
							{checkTable && (
								<BreakDownBox
									checkTable={checkTable}
									filterBy={filterBy}
									selectedCode={selectedCode}
									projectCode={selectedProjectCode}
									departmentId={selectedDepartmentId}
									selectedStatus={selectedStatus}
									fromDate={fromDate}
									toDate={toDate}
									year={selectedYear}
								/>
							)}
							{hQHomePage && (
								<BreakDownBox
									filterBy={filterBy}
									selectedCode={selectedCode}
									projectCode={selectedProjectCode}
									departmentId={selectedDepartmentId}
									selectedStatus={selectedStatus}
									fromDate={fromDate}
									toDate={toDate}
									view={view}
									year={selectedYear}
								/>
							)}
						</>
					)}
				</>
			)}
		</div>
	)
}

export default FilterByCellOrMission
