import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import {
	Container,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
} from '@mui/material'

import { useCurrentUser } from '../../CurrentUserContext'

import OooTable from '../../UI/OooTable'
import Header from '../../UI/Header'
import './style.css'

import { sortAlphabetically } from '../../functions'

const Profile = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()

	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<Container className="topContainer">
				<div style={{ borderBottom: '1px solid #eae8e8' }}>
					<h2>{currentUser.userName}</h2>
					<h3>{currentUser.email}</h3>
				</div>

				<div className="mainContainer">

					<div className="locationContainer" style={{ borderBottom: '1px solid #eae8e8' }}>
					{currentUser.project ? (
						<Table
							stickyHeader
							aria-label="sticky table"
						>
							<TableHead>
								<TableRow>
									<TableCell><h2>{t('cell')}</h2></TableCell>
									<TableCell><h2>{t('mission')}</h2></TableCell>
									<TableCell><h2>{t('project')}</h2></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										<span>{t('code')}:</span> {currentUser.project.cell.code}
									</TableCell>
									<TableCell>
										<span>{t('code')}:</span> {currentUser.project.mission.code}
										<br />
										<span>{t('name')}:</span> {currentUser.project.mission.name}
									</TableCell>
									<TableCell>
										<span>{t('code')}:</span> {currentUser.project.code}
										<br />
										<span>{t('name')}:</span> {currentUser.project.name}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					) : (
						<h2>{t('headquarters')}</h2>
					)}
					</div>

					{currentUser.departments.length ? (
						<div className="departmentsContainer" style={{ borderBottom: '1px solid #eae8e8' }}>
							<h2>{t('departments')}</h2>
							{currentUser.departments.length === 1 ? (
								<p>{currentUser.departments[0].name}</p>
							) : (
								<div className="aligned-left-div">
									<ul className="departmentsList">
										{sortAlphabetically(currentUser.departments, "name")
										.map(({ id, name }) => (
											<li key={id}>{name}</li>
										))}
									</ul>
								</div>
							)}
						</div>
					) : null}

					{currentUser.groups.length ? (
						<div className="groupsContainer" style={{ borderBottom: '1px solid #eae8e8' }}>
							<h2>{t('joined_groups')}</h2>
							{currentUser.groups.length === 1 ? (
								<p>{currentUser.groups[0].group}</p>
							) : (
								<div className="aligned-left-div">
									<ul className="groupsList">
										{sortAlphabetically(currentUser.groups, "group")
										.map(({ id, group }) => (
											<li key={id}>{group}</li>
										))}
									</ul>
								</div>
							)}
						</div>
					) : null}

				</div>

				{currentUser.project
					? currentUser.departments.map(({ id }, i) => (
							<OooTable
								key={id}
								userId={currentUser.id}
								departmentId={id}
								projectCode={currentUser.project.code}
								showEmail={false}
								hideInstructions={i !== 0}
							/>
					  ))
					: null}
			</Container>
		</>
	)
}

export default Profile
