import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useTranslation } from 'react-i18next'
import CorrectiveMeasuresDataGrid from './CorrectiveMeasuresDataGrid'
import Progress from '../Progress'

const CorrectiveMeasuresTable = ({ checks }) => {
	const { t } = useTranslation()
	const CORRECTIVE_MEASURES = loader(
		'./graphql/correctiveMeasuresByKoboDeploymentUids.graphql'
	)

	const koboDeploymentUids =
		checks.length && checks.map(({ deployedFormUid }) => deployedFormUid)

	const [
		getCorrectiveMeasures,
		{ data: { correctiveMeasuresByKoboDeploymentUids = null } = {}, loading },
	] = useLazyQuery(CORRECTIVE_MEASURES)

	useEffect(() => {
		if (checks.length) {
			getCorrectiveMeasures({
				variables: {
					koboDeploymentUids,
				},
			})
		}
	}, [checks])

	return (
		<>
			<div
				style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}
			>
				<h3
					style={{
						width: 'fitContent',
						borderBottom: '2px solid #41403e',
					}}
				>
					{t('list_of_cm')}
				</h3>
			</div>
			{loading ? (
				<Progress />
			) : (
				correctiveMeasuresByKoboDeploymentUids && (
					<CorrectiveMeasuresDataGrid rows={correctiveMeasuresByKoboDeploymentUids} />
				)
			)}
		</>
	)
}

export default CorrectiveMeasuresTable
