import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useTranslation } from 'react-i18next'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { Paper, /*Select, MenuItem*/ } from '@mui/material'

import { enumCorrectiveMeasuresStatus } from '../../../clientConstants'
import { enumDataGrid, getDataGridCappedHeight } from '../../functions/helpers'

const UPDATE_CORRECTIVE_MEASURES = loader(
	'./graphql/updateCorrectiveMeasures.graphql'
)

const CorrectiveMeasuresDataGrid = ({ rows }) => {
	const { t } = useTranslation()

	const columns = [
		{
			field: 'mission',
			headerName: t('mission'),
			valueGetter: ({ row }) => row.koboDeployment.project.mission.name,
			flex: 1,
		},
		{
			field: 'project',
			headerName: t('project'),
			valueGetter: ({ row }) => row.koboDeployment.project.name,

			flex: 1,
		},
		{
			field: 'corrective measures',
			headerName: t('corrective_measures'),
			renderCell: ({ row }) => {
				return (
					<Link
						to={`/corrective-measures/${row.koboDeployment.deployedFormUid}`}
					>
						{row.requiredMeasures}
					</Link>
				)
			},
			flex: 2,
		},
		{
			field: 'functionInCharge',
			headerName: t('in_charge'),
			valueGetter: ({ row }) => row.functionInCharge,
			flex: 1,
		},
		{
			field: 'dueDate',
			headerName: t('due_date'),
			renderCell: ({ row }) => {
				return row.dueDate ? (
					<Moment format="DD-MM-YYYY">{new Date(Number(row.dueDate))}</Moment>
				) : (
					'-'
				)
			},
			flex: 1,
		},
		{
			field: 'status',
			headerName: t('status'),
			renderCell: ({ row }) => {
				return loading ? (
					<span>Loading ...</span>
				) : //If status === 'overdue' user can not update the status
				// row.status === enumCorrectiveMeasuresStatus.OVERDUE ? (
				// 	<Select defaultValue={row.status} disabled>
				// 	<MenuItem key={1} value={row.status}>
				// 		<span>{t('overdue')}</span>
				// 	</MenuItem>
				// 	</Select>
				// ) : (
				// 	<Select
				// 		labelId="InputLabel"
				// 		name="status"
				// 		color="secondary"
				// 		disabled/*={row.status === enumCheckStatus.OVERDUE}*/
				// 		defaultValue={row.status || ''}
				// 		onChange={({ target: { value } }) => {
				// 			return updateCorrectiveMeasures({
				// 				variables: {
				// 					input: {
				// 						status: value,
				// 						id: row.id,
				// 					},
				// 				},
				// 			})
				// 		}}
				// 	>
				// 		{[
				// 			{ label: t('pending'), value: enumCorrectiveMeasuresStatus.PENDING },
				// 			{ label: t('completed'), value: enumCorrectiveMeasuresStatus.COMPLETED },
				// 			{ label: t('overdue'), value: enumCorrectiveMeasuresStatus.OVERDUE },
				// 		].map((el, i) => (
				// 			<MenuItem key={i} value={el.value}>
				// 				{el.label}
				// 			</MenuItem>
				// 		))}
				// 	</Select>
				// )
				row.status === enumCorrectiveMeasuresStatus.PENDING ? (
					t('pending')
				) : row.status === enumCorrectiveMeasuresStatus.COMPLETED ? (
					t('completed')
				) : row.status === enumCorrectiveMeasuresStatus.OVERDUE ? (
					t('overdue')
				) : ''	
			},

			flex: 1,
		},
	]

	const [/*updateCorrectiveMeasures,*/ { loading }] = useMutation(
		UPDATE_CORRECTIVE_MEASURES
	)

	return (
		<Paper
			style={{
				width: '100%',
				height: `${getDataGridCappedHeight(rows)}px`,
			}}
		>
			<DataGridPro
				rowHeight={enumDataGrid.ROW_HEIGHT}
				columns={columns}
				rows={rows}
				components={{ Toolbar: GridToolbar }}
			/>
		</Paper>
	)
}
export default CorrectiveMeasuresDataGrid
