import React from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import Progress from '../Progress'
import { calculateAvgQuarterScore } from './functions'
import { getColorCodeFromIndex } from '../../functions/helpers'

const ALL_DEPARTMENTS = loader(
	'../../pages/Department/graphql/allDepartments.graphql'
)

const LineGraphOne = ({ departmentId, koboDeploymentArray }) => {
	const { t } = useTranslation()
	const labels = [
		t('average_Q1'),
		t('average_Q2'),
		t('average_Q3'),
		t('average_Q4'),
	]
	const options = {
		scales: {
			y: {
				beginAtZero: true,
				suggestedMax: 100,
                ticks: {
                    // Include a percent sign in the ticks
                    callback: function(value, index, ticks) {
                        return value + "%";
                    }
                }
			},
		},
	}
	const data = {
		labels,
		datasets: [],
	}

	const { data: { allDepartments = null } = {}, loading: departmentsLoading } =
		useQuery(ALL_DEPARTMENTS)
	if (departmentsLoading) return <Progress />

	return (
		<>
			{allDepartments && (
				<>
					<h3>{t('evolution_avg_quarterly_by_department')}</h3>
					<Line
						data={{
							...data,
							datasets: allDepartments.map(({ id, name }, i) => {
								return {
									key: i,
									label: name,
									data: labels.map((quarter, i) =>
										calculateAvgQuarterScore({
											quarter: i + 1,
											selectedDepartmentId: Number(id),
											koboDeploymentArray,
										})
									),
									fill: false,
									backgroundColor: getColorCodeFromIndex(i),
									borderColor: getColorCodeFromIndex(i, 90),
								}
							}),
						}}
						options={options}
					/>
				</>
			)}
		</>
	)
}

export default LineGraphOne
