/**
 * Answer Component
 *
 * The Answer component displays a list of answered questions provided by the user.
 * It presents the user's responses to each question in a clear and organized manner.
 *
 * @param {object[]} answers - An array of answer objects, each containing the answer content, question, and other details.
 * @returns {JSX} A component that renders the user's answers to the questions.
 */
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'

import './styles.css'

const Answer = ({ answers }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const language = window.localStorage.i18nextLng

	useEffect(() => {
		window.scrollTo(0, 0)
	})

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<h2>
				{t('you_have_answered_questions', { count: answers.length})}
			</h2>
			{answers.map(({ id: answerId, answer, answerFr, question }) => {
				const { label, labelFr } = question
				return (
					<div key={answerId} className="questions-container">
						{language === 'fr' ? (
							<>
								<div className="left">
									<h3 className="text-q">Q.</h3>
									<h3>-</h3>
								</div>
								<div className="right">
									<h4>{labelFr}</h4>
									<p>{answerFr}</p>
								</div>
							</>
						) : (
							<>
								<div className="left">
									<h3 className="text-q">Q.</h3>
									<h3>-</h3>
								</div>
								<div className="right">
									<h4>{label}</h4>
									<p>{answer}</p>
								</div>
							</>
						)}
					</div>
				)
			})}
		</>
	)
}

export default Answer
