/**
 * Error Component
 *
 * The Error component is used to display an error message to the user. It provides information about the error, including the user's email and instructions for contacting support.
 *
 * @returns {JSX} A component for displaying an error message to the user.
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Navigate } from 'react-router-dom'
import Header from '../../UI/Header'
import './styles.css'

const Error = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<div className="supportContainer">
				<h2>{t('error')}</h2>
				<p>
					{t('access_error')} <span>{currentUser.email}</span>
				</p>
				<p>
					{t('screenshot_msg')}
					<a href={'mailto:WeCheck@brussels.msf.org'}>WeCheck</a>
				</p>
			</div>
		</>
	)
}

export default Error
