import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { MenuItem, InputLabel, Select } from '@mui/material'

import { useCurrentUser } from '../../CurrentUserContext'

import ProjectSummary from '../ProjectSummary'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import PendingChecksTable from '../../UI/CheckTable/PendingChecksTable'
import DetailedChecksTable from '../../UI/CheckTable/DetailedChecksTable'
import { StyledFormControl } from '../Project/useStyles'

import { enumCheckStatus, enumLocalStorageKey } from '../../../clientConstants'

const ALL_DEPARTMENTS = loader('../AllChecks/graphql/allDepartments.graphql')
const KOBO_DEPLOYMENT = loader(
	'../Project/graphql/koboDeploymentByProjectAndDepartment.graphql'
)

const selectedDepartmentIdStorageKey = enumLocalStorageKey.SELECTED_CHECKS_DEPARTMENT_ID

const PeriodChecks = () => {
	const { code } = useParams()
	const currentUser = useCurrentUser()
	const { t } = useTranslation()

	const [selectedDepartmentId, setSelectedDepartmentId] = useState(() => {
		return window.localStorage.getItem(selectedDepartmentIdStorageKey)
	})
	const months = [
		t('january'),
		t('february'),
		t('march'),
		t('april'),
		t('may'),
		t('june'),
		t('july'),
		t('august'),
		t('september'),
		t('october'),
		t('november'),
		t('december'),
	]

	const {
		data: { allDepartments = null } = {},
		loading: allDepartmentsLoading,
	} = useQuery(ALL_DEPARTMENTS)

	const [
		koboDeploymentData,
		{ data: { koboDeploymentByProjectAndDepartment = null } = {}, loading },
	] = useLazyQuery(KOBO_DEPLOYMENT)

	useEffect(() => {
		if (currentUser) {
			koboDeploymentData({
				variables: {
					year: Number(new Date().getFullYear()),
					projectCode: code,
					departmentId: selectedDepartmentId || currentUser.departments[0].id,
				},
			})
		}
	}, [currentUser, selectedDepartmentId])

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<Header />
			<h3>{`${t('monthly_pending_checks')} - ${
				months[new Date().getMonth()]
			} ${new Date().getFullYear()}`}</h3>
			{allDepartments && (
				<StyledFormControl variant="outlined" required color="secondary">
					<InputLabel id="Department">{t('department')}</InputLabel>
					<Select
						labelId="departmentInputLabel"
						name="allDepartments"
						value={selectedDepartmentId || currentUser.departments[0].id}
						onChange={({ target: { value } }) => {
							setSelectedDepartmentId(value)
							window.localStorage.setItem(
								selectedDepartmentIdStorageKey,
								value
							)
						}}
					>
						{allDepartments.map(({ id, name }) => (
							<MenuItem key={id} value={id}>
								{name}
							</MenuItem>
						))}
					</Select>
				</StyledFormControl>
			)}
			{loading ? (
				<div>
					<Progress />
				</div>
			) : (
				koboDeploymentByProjectAndDepartment && (
					<>
						<PendingChecksTable
							rows={koboDeploymentByProjectAndDepartment
								.filter(({ status }) => status === enumCheckStatus.SENT)
								.map(({ check, createdAt, link, id }) => ({
									check,
									createdAt,
									link,
									id,
								}))}
						/>
						<h3>{`${t('monthly_completed_checks')} - ${
							months[new Date().getMonth()]
						} ${new Date().getFullYear()}`}</h3>
						<div style={{ maxWidth: '95%', margin: 'auto' }}>
							<DetailedChecksTable
								koboDeploymentArr={koboDeploymentByProjectAndDepartment.filter(
									({ updatedAt, status }) =>
										status === enumCheckStatus.RECEIVED &&
										new Date(Number(updatedAt)).getMonth() ===
											new Date().getMonth()
								)}
								selectedStatus="received"
							/>
						</div>

						<ProjectSummary
							code={code}
							departmentId={
								selectedDepartmentId || currentUser.departments[0].id
							}
						/>
					</>
				)
			)}
		</>
	)
}

export default PeriodChecks
