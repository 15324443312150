import React from 'react'
import { useTranslation } from 'react-i18next'
import { uniq } from 'ramda'
import { StyledPaper } from './useStyles'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import { calculateAvgScoreByCheckPerMonth } from './functions'
import { enumDataGrid, getDataGridCappedHeight } from '../../functions/helpers'

const TableOne = ({ koboDeploymentArray, months }) => {
	const { t } = useTranslation()
	const language = window.localStorage.i18nextLng
	// const [selectedMonth, setSelectedMonth] = useState()

	const monthsColumns = months.map((month, i) => {
		return {
			field: month,
			headerName: t(month),
			renderCell: ({ row }) => {
				const avgScorePerMonth = calculateAvgScoreByCheckPerMonth({
					koboDeploymentArray,
					checkName: row.name,
					month: i,
				})

				if (typeof avgScorePerMonth === 'number') {
					return `${avgScorePerMonth}%`
				} else {
					return '%'
				}
			},
			flex: 1,
		}
	})

	const gridColumns = [
		{
			field: 'check',
			headerName: t('check'),
			valueGetter: ({ row }) =>
				language === 'fr'
					? row.labelFr.replace(/^[-\d\s]*/, '')
					: row.label.replace(/^[-\d\s]*/, ''),
			flex: 2.5,
		},
		...monthsColumns,
	]

	const uniqueCheckNamesArr = uniq(
		koboDeploymentArray.map(({ check }) => check)
	)

	return (
		<>
			<h3>{t('departments_checks_results_by_month')}</h3>
			{/* If too many results rows, cap the height of the data grid and make it scrollable */}
			<StyledPaper
				style={{
					height: `${getDataGridCappedHeight(uniqueCheckNamesArr)}px`,
				}}
			>
				{uniqueCheckNamesArr.length > 0 && (
					<DataGridPro
						rowHeight={enumDataGrid.ROW_HEIGHT}
						columns={gridColumns}
						rows={uniqueCheckNamesArr}
						components={{ Toolbar: GridToolbar }}
					/>
				)}
			</StyledPaper>
		</>
	)
}

export default TableOne
