
export const enumGroup = Object.freeze({
	SUPER_ADMIN: 'superAdmin',
	FRRIC: 'SEC_OCB_USR_FIN_FRRIC_RegionalOfficers',
	HQ: 'hq',
	COORDO: 'coordo',
})

export const enumOrgEntity = Object.freeze({
	CELL: 'cell',
	MISSION: 'mission',
	ALL_CELLS: 'all cells',
	ALL_MISSIONS: 'all missions',
	ALL_PROJECTS: 'all projects',
	ALL_DEPARTMENTS: 'all departments',
})	

export const enumCheckStatus = Object.freeze({
	ALL: 'all',
	RECEIVED: 'received',
	SENT: 'sent',
	OVERDUE: 'overdue',
	CANCELED: 'canceled',
})

export const enumCorrectiveMeasuresPriority = Object.freeze({
	LOW: 'low',
	MEDIUM: 'medium',
	HIGH: 'high',
})
export const enumCorrectiveMeasuresStatus = Object.freeze({
	PENDING: 'pending',
	COMPLETED: 'completed',
	OVERDUE: 'overdue',
})

export const enumDataYears = Object.freeze(
	[2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
)

export const enumLocalStorageKey = Object.freeze({
	SELECTED_ORG_ENTITY_TYPE: 'selectedOrgEntityType',
	SELECTED_CELL_OR_MISSION_CODE: 'selectedCellOrMissionCode',
	SELECTED_PROJECT_CODE: 'selectedProjectCode',
	SELECTED_DEPARTMENT_ID: 'selectedDepartmentId',
	SELECTED_YEAR: 'selectedYear',
	SELECTED_CHECKS_DEPARTMENT_ID: 'selectedDepartmentIdForChecks',
	SELECTED_BATCHES_ORG_ENTITY_TYPE: 'selectedOrgEntityTypeForBatches',
	SELECTED_BATCHES_CELL_OR_MISSION_CODE: 'selectedCellOrMissionCodeForBatches',
})

export const enumWebLinks = Object.freeze({
	// PowerBI report "PBI-OCB-WECHECK-PROD":
	POWERBI_REPORT: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=6e2e3d73-1f58-4c43-b9f4-4ea136df5b6f&ctid=4d9dd1af-83ce-4e9b-b090-b0543ccc2b31',
})
