import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { Tooltip } from '@mui/material'

import { enumCheckStatus } from '../../../clientConstants'

const DetailedChecksTable = ({ koboDeploymentArr, selectedStatus, page }) => {
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()

	const columns = [
		{
			field: 'check',
			headerName: t('name'),
			valueGetter: ({ row }) => row.check.label,
			renderCell: ({ row }) => {
				//Only in latestChecks page we show the check label as a hyper link to Project page
				return page === 'latestChecks' ? (
					<Link to={`/project/${row.check.name}`}>
						{language === 'fr'
							? //Remove Number before - in the check label
							  row.check.labelFr.replace(/^[-\d\s]*/, '')
							: row.check.label.replace(/^[-\d\s]*/, '')}
					</Link>
				) : language === 'fr' ? (
					//Remove Number before - in the check label
					row.check.labelFr.replace(/^[-\d\s]*/, '')
				) : (
					row.check.label.replace(/^[-\d\s]*/, '')
				)
			},
			flex: 1.5,
		},
		{
			field: 'createdAt',
			headerName: t('receive_date'),
			renderCell: ({ row }) => {
				return (
					<Moment format="DD-MM-YYYY">{new Date(Number(row.createdAt))}</Moment>
				)
			},
			flex: 1,
		},
		{
			field: 'updatedAt',
			headerName: t('submit_date'),
			renderCell: ({ row }) => {
				return row.status === enumCheckStatus.RECEIVED ? (
					<Moment format="DD-MM-YYYY">{new Date(Number(row.updatedAt))}</Moment>
				) : (
					'-'
				)
			},

			flex: 1,
		},
		{
			field: 'link',
			headerName: t('link'),
			renderCell: ({ row }) => {
				return row.status === enumCheckStatus.RECEIVED ? (
					<Link to={`/receivedChecks/${row.deployedFormUid}`}>
						{t('view_answers')}
					</Link>
				) : row.status === enumCheckStatus.OVERDUE || row.status === enumCheckStatus.CANCELED ? (
					<span>{t('archived')}</span>
				) : (
					<a target="_blank" rel="noreferrer" href={row.link}>
						{t('check_form')}
					</a>
				)
			},

			flex: 1,
		},
		{
			field: 'status',
			headerName: t('status'),
			valueGetter: ({ row }) => {
				return row.status === enumCheckStatus.RECEIVED
					? t('submitted')
					: row.status === enumCheckStatus.SENT
					? t('pending')
					: row.status === enumCheckStatus.OVERDUE
					? t('overdue')
					: row.status === enumCheckStatus.CANCELED
					? t('canceled')
					: ''
			},

			flex: 1,
		},

		{
			field: 'score',
			headerName: t('score'),
			valueGetter: ({ row }) =>
				typeof row.score === 'number' && row.score > -1 ? `${row.score}%` : '-',
			flex: 1,
		},
		{
			field: 'impact',
			headerName: t('impact'),
			flex: 1,
			valueGetter: ({ row }) => row.impact,
		},

		{
			field: 'corrective measures',
			headerName: t('corrective_measures'),
			flex: 1,
			renderCell: ({ row }) => {
				return row.correctiveMeasures ? (
					<Link to={`/corrective-measures/${row.deployedFormUid}`}>
						{row.correctiveMeasures.requiredMeasures ||
							t('submit_corrective_measures')}
					</Link>
				) : (
					''
				)
			},
		},
		{
			field: 'function in charge',
			headerName: t('person_in_charge'),
			flex: 1,
			valueGetter: ({ row }) => {
				return (
					(row?.correctiveMeasures &&
						row.correctiveMeasures?.functionInCharge) ||
					''
				)
			},
			renderCell: ({ row }) => (
				<Tooltip
					title={
						(row?.correctiveMeasures &&
							row.correctiveMeasures?.functionInCharge) ||
						''
					}
				>
					<p style={{ fontSize: '12px' }}>
						{(row?.correctiveMeasures &&
							row.correctiveMeasures?.functionInCharge) ||
							''}
					</p>
				</Tooltip>
			),
		},
		{
			field: 'due date',
			headerName: t('due_date'),
			flex: 1,
			renderCell: ({ row }) => {
				return row.correctiveMeasures && row.correctiveMeasures?.dueDate ? (
					<Moment format="DD-MM-YYYY">
						{new Date(Number(row.correctiveMeasures.dueDate))}
					</Moment>
				) : (
					''
				)
			},
		},
		{
			field: 'priority',
			headerName: t('priority'),
			flex: 1,
			valueGetter: ({ row }) => {
				return row.correctiveMeasures
					? row.correctiveMeasures.priority
						? row.correctiveMeasures.priority
						: ''
					: t('n_a')
			},
			renderCell: ({ row }) => (
				<Tooltip
					title={
						row.correctiveMeasures
							? row.correctiveMeasures.priority
								? row.correctiveMeasures.priority
								: ''
							: t('n_a')
					}
				>
					<p style={{ fontSize: '12px' }}>
						{row.correctiveMeasures
							? row.correctiveMeasures.priority
								? row.correctiveMeasures.priority
								: ''
							: t('n_a')}
					</p>
				</Tooltip>
			),
		},
		{
			field: 'comment',
			headerName: t('comment'),
			flex: 1,
			valueGetter: ({ row }) => row?.comment || '',
			renderCell: ({ row }) => (
				<Tooltip title={row?.comment || ''}>
					<p style={{ fontSize: '12px' }}>{row?.comment || ''}</p>
				</Tooltip>
			),
		},
	]

	// Sort arrays alphabetically
	const sortAlphabetically = (arr) => {
		return arr.slice().sort((a, b) => (a.check.label > b.check.label ? 1 : -1))
	}
	return (
		<DataGridPro
			autoHeight
			columns={columns}
			rows={sortAlphabetically(koboDeploymentArr).filter(({ status }) =>
				selectedStatus === enumCheckStatus.ALL ? status : status === selectedStatus
			)}
			components={{ Toolbar: GridToolbar }}
		/>
	)
}

export default DetailedChecksTable
