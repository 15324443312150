import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { MenuItem, InputLabel, Select } from '@mui/material'

import AssignProjectsToBatches from '../../AssignProjectsToBatches'
import Progress from '../../../UI/Progress'
import { StyledFormControl } from './useStyles'
import '../styles.css'

import { enumOrgEntity, enumLocalStorageKey } from '../../../../clientConstants'
import { sortAlphabetically } from '../../../functions'

const CELLS = loader('./graphql/cells.graphql')
const MISSIONS = loader('./graphql/missions.graphql')

const selectedCodeStorageKey = enumLocalStorageKey.SELECTED_BATCHES_CELL_OR_MISSION_CODE
const filterByStorageKey = enumLocalStorageKey.SELECTED_BATCHES_ORG_ENTITY_TYPE

const CellAndMission = () => {
	const { t } = useTranslation()

	const [filterBy, setFilterBy] = useState(() => {
		return window.localStorage.getItem(filterByStorageKey) || enumOrgEntity.MISSION
	})

	const [selectedCode, setSelectedCode] = useState(() => {
		return window.localStorage.getItem(selectedCodeStorageKey) || null
	})

	const { data: { cells = null } = {}, cellsLoading } = useQuery(CELLS)
	const { data: { missions = null } = {}, missionsLoading } = useQuery(MISSIONS)

	return (
		<>
			{cellsLoading || missionsLoading ? (
				<Progress />
			) : (
				<>
					{cells && missions && (
						<>
							<div className="formContainer">
								<StyledFormControl
									variant="outlined"
									required
									color="secondary"
								>
									<InputLabel id="filter-by">{t('filter_by')}</InputLabel>
									<Select
										labelId="filterbyInputLabel"
										name="filter"
										value={filterBy}
										onChange={({ target: { value } }) => {
											setFilterBy(value)
											window.localStorage.setItem(filterByStorageKey, value)
											setSelectedCode(null)
											window.localStorage.removeItem(selectedCodeStorageKey)
										}}
									>
										<MenuItem key={enumOrgEntity.CELL} value={enumOrgEntity.CELL}>
											{t('cell')}
										</MenuItem>
										<MenuItem key={enumOrgEntity.MISSION} value={enumOrgEntity.MISSION}>
											{t('mission')}
										</MenuItem>
									</Select>
								</StyledFormControl>
								<StyledFormControl
									variant="outlined"
									required
									color="secondary"
								>
									<InputLabel id="missionCode">
										{filterBy === enumOrgEntity.MISSION ? t('mission') : t('cell')}
									</InputLabel>
									<Select
										labelId="MissionInputLabel"
										name="missionsOrCells"
										value={selectedCode || ''}
										onChange={({ target: { value } }) => {
											setSelectedCode(value)
											window.localStorage.setItem(selectedCodeStorageKey, value)
										}}
									>
										{filterBy === enumOrgEntity.CELL
											? sortAlphabetically(cells, "code").map(({ id, code, name }) => (
													<MenuItem key={id} value={code}>
														{name || code}
													</MenuItem>
											  ))
											: sortAlphabetically(missions, "code").map(
													({ id, code, name }) => (
														<MenuItem key={id} value={code}>
															{code === enumOrgEntity.ALL_MISSIONS
																? name
																: `${code} - ${name}`}
														</MenuItem>
													)
											  )}
									</Select>
								</StyledFormControl>
							</div>
							{selectedCode && (
								<AssignProjectsToBatches
									code={selectedCode}
									filterBy={filterBy}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	)
}

export default CellAndMission
